#login-container {
  #login-wrapper {
    justify-items: center;
    flex-direction: column;
    max-width: 400px;
    text-align: center;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    .logos-login{
      display: flex;
      flex-direction: row;
    }
    #login-main {
      margin: auto 0;

      #login_your_account {
        color: red;
        font-size: 1.5rem;
        margin: 2rem 0;
      }

      #login-form-wrapper{
        padding: 0;
        margin: 0;

        #login-form-content{
          padding : 0rem 2.5rem;

          label{
            text-align: left;
            color: black;
          }

          #login-message-error{
            color: red;
          }
        }

        #form-footer{
          padding: 1rem;
          background-color: grey;
        }
      }

      #btn-login-submit {
        font-weight: normal;
        margin: 0 0 2rem 0;
        padding: 0.5rem 2rem;
        font-size: 1rem;
      }
    }

    #login-footer {
      p {
        margin: 0 0 1rem 0;
      }
    }
  }
}